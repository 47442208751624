.module.column.column-two{
  max-width: 480px;
  .text{
    @extend .paragraph-normal;
  }
  .wrapper{
    @include clearfix();
    article{
      header {
        .icon {
          position: relative;
          height: 100px;
          img {
            @include hCenter(absolute);
            bottom: 0;
            height: 100%;
            width: auto;
          }
        }
        p{
          text-align: center;
          @extend .title;
          margin-top: $boxPadding;
        }
        h3{
          text-align: center;
          @extend .subtitle;
        }
      }
      h4{
        @extend .subtitle;
      }
    }
  }
  /*.link{
    display: inline-block;
    margin-top: $boxPadding;
    svg{
      margin-top: 4px;
      margin-right: 7px;
      float: left;
    }
  }*/
  @media (min-width: $break-small ){
    max-width: 970px;
    padding-bottom: $boxPadding * 2;
    .wrapper{
      padding: $boxPadding 0;
    }
    article{
      left: auto;
      @include transform(translateX(0));
      float: left;
      width: 50%;
      @include box-sizing(border-box);
      &:nth-of-type(odd){
        padding-right: $boxPadding;
      }
      &:nth-of-type(even){
        padding-left: $boxPadding;
      }
    }
  }
}