.intro {
  > article {
    margin-top: 50vh;
    @include transitionTransform(0.5s cubic-bezier($easeOutQuint));
    > header {
      background-color: white;
      .content {
        position: relative;
        width: 100%;
        left: 50%;
        @include transform(translateX(-50%));
        h2 {
          @include hCenter(relative);
          text-align: center;
          padding: 0 $boxPadding;
          padding-top: $boxPadding * 3;
        }
        .buttons {
          @include hCenter(relative);
          display: inline-block;
          a,span {
            text-decoration: none;
            float: left;
            margin: 0;
          }
          .dot {
            display: block;
            position: relative;
            top: 8px;
            width: 18px;
            height: 2px;
            float: left;
            background-image: url('../assets/ui/text_dot.svg');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
      }
    }
    &.hidden{
      @include transform(translateY(700px));
      @include transform(translateY(50vh));
    }
    @media (max-width: $break-small) {
      margin-top: 50%;
    }
    html.touch &{
      margin-top: 50%;
      @media (min-aspect-ratio: 1/1){
        margin-top: 25%;
      }
      &.hidden{
        @include transform(translateY(75vh));
      }
    }
  }
}