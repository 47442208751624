.description-box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  .wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    .box {

      //visibility: hidden;
      position: absolute;
      overflow: hidden;
      height:100%;
      width: 100%;
      color: white;
      padding: 10px;
      box-sizing: border-box;
      &:not(.intro){
        cursor: pointer;
      }
      .content {
        position:relative;
        top: 100%;
        transition: transform 0.4s cubic-bezier($easeOutCirc);
        transform: translateY(-100%);
        //bottom: 0;
        //top: 100%;
        width: 100%;
        //height: 50%;
        background-color: #fff;
        text-align: center;
        #logo{
          margin-top: $boxPadding * 2;
        }
        #cta{
          width:100%;
          bottom:0px;
          padding-top:20px;
          cursor: pointer;
          svg{
            @include transform(rotate(90deg) translateY(50%));
            left: 50%;
            margin-bottom: 50px;
            bottom: $boxPadding * 2;
          }
        }
        h1 {
          @extend .title;
          margin-bottom: $boxPadding / 2;
        }

        h2 {
          @extend .header;
//          font-size: 50px;
          margin-bottom: $boxPadding / 2;
        }
        h3 {
          @extend .title;
          margin: 0;
        }
        p {
          @extend .paragraph-small;
          margin-top: $boxPadding / 2;
        }
        a{
          @extend .title;
          margin-top: $boxPadding / 2;
          margin-bottom: 0;
          display:block;
        }
        h1, h2, h3, p, a{
          //-webkit-font-smoothing: auto;
          color: white;
        }

        .center {
          @include box-sizing(border-box);
          padding: 8vw 8vw;
          width: 100%;
          @media (min-width: $break-xsmall) {
            padding: 0 $boxPadding * 1.5;
            html.no-touch &{
              padding: 0 $boxPadding * 2;
            }
          }
        }
        &.top{
          transition: transform 0s;
          transform: translateY(-100%);
        }
        &.bottom{
          transition: transform 0s;
          transform: translateY(100%);
        }
        &.middle{
          transition: transform 0.8s cubic-bezier($easeOutQuint);
          transform: translateY(0%);
        }
      }
      &.intro {
        //@include transitionTransform(0.5s cubic-bezier($easeOutQuint));
        //@include transition(height 0.3s cubic-bezier($easeOutQuint));
        .content{
          h1, h2, h3, p, a{
            //-webkit-font-smoothing: antialiased;
            color: $dark;
          }
          p{
            display: none;
          }
        }
      }
      @media (max-width: $break-xsmall - 1) {
        @for $i from 1 through 10 {
          &:nth-of-type(#{$i}) {
            transform: translateY(($i - 1)*100%);
          }
        }
      }
    }
  }
  @media (min-width: $break-xsmall) {
    @include center(fixed);
    @include box-sizing(border-box);
    transition: transform 0.4s cubic-bezier($easeOutCirc), left 0.4s cubic-bezier($easeOutCirc), height 0.4s cubic-bezier($easeOutCirc), bottom 0.4s cubic-bezier($easeOutCirc);
    z-index: 10;
    bottom: auto;
    padding: $boxPadding $boxPadding/2;
    max-width: $break-small / 2;
    html.touch &{
      height: 75%;
      min-height: 700px;
    }
    .wrapper .box{
      padding: 0;
      .content{
        position: absolute;
        top:0;
        transform: translateY(0);
        height: 100%;

        h1{
          margin-bottom: $boxPadding * 2 - 12px;
        }
        h2{
          margin-bottom: $boxPadding * 2 - 12px;
        }
        p{
          margin-top: $boxPadding * 2 - 12px;
        }
        a{
          margin-top: $boxPadding * 2 - 12px;
        }
        #cta{
          position: absolute;
          margin-bottom: 0;
        }
        .center{
          @include vCenter(absolute);
          //html.no-touch & {
          //  padding: 0 8vw;
          //}
        }
      }
    }

    &.left{
      height: 75%;
    }
    .intro{
      p{
        display: block ! important;
      }
    }
  }

  @media (min-width: $break-small) {

    width: 45vw;
    width: calc(50vw - 20px);
    max-width: round($max-width-frontpage / 3 - $boxPadding / 2);
    //min-height: 400px;
    &.left{
      left: 25%;
      left: calc(25% + 6px);
      html.touch &{
        left: 50%;
      }
    }
  }

  @media (min-width: $break-medium) {
    width: 30vw;
    width: calc((100vw - 40px) / 3);
    &.left{
      left: 16.666%;
      left: calc(100% / 6 + 6px);
      html.touch &{
        left: 50%;
      }
    }
  }
  @media (min-width: $max-width-frontpage) {
    &.left {
      left: 50%;
      $leftPosition: round($max-width-frontpage / 2 - $boxPadding / 2);
      transform: translate(-$leftPosition, -50%);
      html.touch &{
        transform: translate(-50%, -50%);
      }
    }
  }

  a:hover {
    color: #fff !important;
  }

  &.hidden {
    .wrapper .box .content{
      transform: translateY(0%);
    }
    @media (min-width: $break-xsmall) {
      .wrapper .box .content{
        transform: translateY(-100%);
      }
      height: 0;
      bottom: 100%;
    }
  }
}