.module.object {
  img{
    width:100%;
    position: relative;
  }
  .background-color-desktop {
    overflow: hidden;
    .description {
      width: 100%;
    }
    .object {
      position: relative;
      padding: $boxPadding-mobile;
      picture{
        width: 100%;
        position: relative;
      }
    }
  }
  &.center{
    .child{
      width:100%;
      display: block;
      position:relative;
      .carousel{
        -webkit-transform-style: preserve-3d;
        @include animation('carousel-rotate 3s cubic-bezier(#{$easeInBackOutBack}) infinite alternate');
        picture{
          @include vCenter(relative);
          backface-visibility: hidden;
          &.second{
            position: absolute;
            @include transform(translateY(-50%) rotateY(180deg));
          }
        }
      }
    }
    &.rotate{
      .object{
        @include perspective(1000px);
        .child{
          left: 50%;
          @include transform-origin(0% 50%);
          //          @include perspective(1000px);
          -webkit-transform-style: preserve-3d;
          @include transitionTransform(0.5s cubic-bezier($easeOutQuint));
          @include transform(rotateY(-30deg) scale(0.95) translateX(-55%));
          @media (min-width: $break-medium) {
            @include transform(rotateY(-30deg) scale(0.9) translateX(-57.5%));
          }
          @media (min-width: $break-xmedium) {
            @include transform(rotateY(-30deg) scale(0.8) translateX(-55.5%));
          }
        }
      }
      &.hoverable{
        .background-color-desktop:hover{
          .child{
            @include transform(rotateY(0deg) translateX(-50%));
          }
        }
      }
    }
  }
  //&.devices{
  //  .child{
  //    left: 50%;
  //    .wrapper{
  //      @include transform(translateX(-50%));
  //    }
  //    @media (min-width: $break-small) {
  //      .wrapper {
  //        position: absolute;
  //        @include transform(translateY(-50%) translateX(-50%));
  //        > picture, > picture img, > img {
  //          max-width: 100%;
  //          width: auto;
  //        }
  //      }
  //    }
  //  }
  //  .render {
  //    width: 100%;
  //    height: 100%;
  //    position: absolute;
  //    @include perspective-origin(50% 80%);
  //    @include perspective(calc(100vw - 40px)); //The perspective itself has to be responsive because the elements within scales.
  //    //So here it follows the dynamic screen width, minus all the fixed paddings
  //    @media (min-width: $break-xsmall) {
  //      @include perspective(calc(100vw - 100px));
  //    }
  //    @media (min-width: $break-small) {
  //      @include perspective(calc(0.8 * (50vw - 50px))); // * 0.8 because the width of the element is 80% of the available space in these screensizes
  //    }
  //    @media (min-width: 980px) {
  //      @include perspective(calc(0.8 * (100vw - 530px)));
  //    }
  //    @media (min-width: $break-large) {
  //      @include perspective($break-large - 530px);
  //    }
  //    .screen {
  //      position: absolute;
  //      background-color: salmon;
  //      @include transform-origin(50% 100%);
  //      overflow: hidden;
  //      &.mobile {
  //        top: 50.5%;
  //        left: 23.2%;
  //        @include transform(translateX(-50%) translateY(-50%) rotateY(-19deg) rotateX(0deg));
  //        width: 23%;
  //        height: 50%;
  //        img {
  //          @include animation('scroll-mobile 10s cubic-bezier(#{$easeInOutCubic}) infinite alternate');
  //        }
  //      }
  //      &.tablet {
  //        top: 43%;
  //        left: 66.5%;
  //        @include transform(translateX(-50%) translateY(-50%) rotateY(10deg) rotateX(3deg) rotateZ(-0.7deg));
  //        width: 42.5%;
  //        height: 69%;
  //        img {
  //          @include animation('scroll-tablet 10s cubic-bezier(#{$easeInOutCubic}) infinite alternate');
  //        }
  //      }
  //    }
  //  }
  //}
  &.fill{
    .child{
      //background-color: salmon;
      width:100%;
      canvas{
        height: auto;
      }
    }
  }
  &.scan{
    .object{
      overflow: hidden;
      .mask{
        position:absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
      video{
        height: 100%;
        @include hCenter(absolute);
        &:last-of-type{
          clip-path: inset(0% 0 80% 0);
        }
      }
    }
  }
  @media (min-width: $break-xsmall) {
    .background-color-desktop .object{
      padding: $boxPadding;
    }
  }
  @media (min-width: $break-small) {
    .background-color-desktop {
      @include flexbox();
      @include flex-direction(row-reverse);
      .object{
        padding: 0;
        width:100%;
        >picture, >picture img, >img {
          max-width: 100%;
          width: auto;
          &.child{
            width: auto;
          }
        }
      }
    }
    &.center{
      .object{
        @include flexbox();
        padding-right: $boxPadding;
        @include justify-content(center);
        .child{
          width: 100%;
        }
      }
      .description +.object{
        padding-right: 0;
        padding-left: $boxPadding;
      }
      &:not(.top){
        .object{
          @include align-items(center);
        }
      }
      &.rotate{
        .child{
          width: 100%;
        }
      }
    }
    &.fill{
      .object{
        @include flexbox();
        .child{
          @include flexbox();
          @include flex(1);
        }
        .fill{
          @include flexbox();
          @include flex-direction(row);
          @include flex(1);
        }
      }
    }
  }
}