.module.video.video-advanced{
  html.android &{
    display: block; //we still want to display the case video
  }
  .aspect{
    img{
      height: 100%;
      @include center(absolute);
      @include transitionTransform(0.5s cubic-bezier($easeInOutCubic));
    }
    .description{
      position: absolute;
      height: 100%;
      .content{
        height:100%;
        max-height: none;
        min-height: 0;
      }
    }
    .play-button{
      position: absolute;
      left: 50%;
      @include transform(translateX(-50%));
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: none;
      padding: 0;
      @include transitionTransform(0.7s cubic-bezier($easeInOutCubic));
      .shape{
        @include center(absolute);
        width: 140px;
        height: 140px;
        background-color: white;
        @include border-radius(50%);
        pointer-events: none;
        svg{
          fill: $dark;
          position: absolute;
          left: 50%;
          top: 50%;
          @include transform(translateX(-39%) translateY(-55%));
        }
        h2{
          @extend .paragraph-small;
          font-size: 11px;
          text-transform: uppercase;
          text-align: center;
          @include center(absolute);
          top: 71%;
        }
      }
      &:focus {
        outline:0;
      }
      &:active{
        .shape{
          background-color: $lightgrey;
        }
      }
    }
  }
  &.open{
    .aspect{
      img{
        @include transform(translateX(-50%) translateY(-150%));
      }
      .play-button{
        @include transform(translateX(-50%) translateY(-60%) scale(0.1));
      }
    }
  }
}