.module.contact-us{
  position: relative;
  background-color: white;
  h2{
    @extend .header;
    @include hCenter(relative);
    padding-bottom: 0.5em;
  }
  .wrapper{
    @include hCenter(relative);
    max-width: $break-medium;
    padding: 80px $boxPadding;
    ul{
      @include hCenter(relative);
      $maxWidth:630px;
      max-width: $maxWidth;
      @include clearfix();
      list-style-type: none;
      padding: 0;
      li{
        display: inline;
        position: relative;
        padding-bottom: $boxPadding * 2;
        text-align: center;
        img{
          @include hCenter(relative);
        }
        h3{
          @extend .title;
        }
        h4{
          @extend .subtitle;
        }
        h5{
          @extend .paragraph-small;
          a{
            color: $dark;
          }
        }
        @media (min-width: $break-xsmall){
          padding: 0;
        //   float: left;
          width: 50%;
          width: calc(100% / 2);
        }
        /*
        @media (max-width: $break-xsmall - 1){
          @include hCenter(relative);
        }
        */
        max-width: $maxWidth/2;
      }
    }
  }
}