.simple-header {
  > article {
    > header {
      .content {
        position: relative;
        top: 100%;
        padding-top: 70px;
        background-color: #fff;
        h2 {
          @include hCenter(relative);
          text-align: center;
          padding: 0 $boxPadding;
          margin-bottom: 26px !important;
        }
        .buttons {
          @include hCenter(relative);
          display: inline-block;
          a {
            text-decoration: none;
            float: left;
          }
          .dot {
            display: block;
            position: relative;
            top: 8px;
            width: 18px;
            height: 2px;
            float: left;
            background-image: url('../assets/ui/text_dot.svg');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
}