&.about{
  list-style: none;
  padding: 0;
  height: auto;
  padding-top: $boxPadding;
//  max-width: $max-width-frontpage;
//  @include hCenter(relative);
  li{
//    @at-root html.no-touch &:hover{
//      .description .content{
//        background-color: darken($lightgrey, 5%);
//        transition: all 200ms
//      }
//      .wrapper .pattern{
////        filter:contrast(1.2);
////        transition: filter 200ms
//      }
//    }
    .description .content{
      transition: all 150ms;
    }
    @include box-sizing(border-box);
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: $boxPadding-mobile;
    @media (min-width: $break-xsmall) {
      margin-bottom: $boxPadding;
    }
    &.capabilities .pattern {
      background-image: url('../assets/content/frontpage/about-capabilities.jpg');
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url('../assets/content/frontpage/about-capabilities@2x.jpg');
      }
    }
    //dance monkey dance;
    $trance-in: color 150ms linear;
    $trance-out: color 250ms linear;
    @at-root html.no-touch &.capabilities{
      &:hover{
        .content h3{
          color:$green;
          transition: $trance-in;
        }
      }
      .content h3{
        transition: $trance-out;
      }
    }
    &.applications .pattern {
      background-image: url('../assets/content/frontpage/about-positions.jpg');
      background-size: 67px 74px !important;
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url('../assets/content/frontpage/about-positions@2x.jpg');
      }
      @media (min-width: $break-small) {
        background-size: 133px 147px !important;
      }
    }
    @at-root html.no-touch &.applications{
      &:hover{
        .content h3{
          color:darken($yellow, 3%);
          transition: $trance-in;
        }
      }
      .content h3{
        transition: $trance-out;
      }
    }
    &.about-us .pattern {
      background-image: url('../assets/content/frontpage/about-us.jpg');
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url('../assets/content/frontpage/about-us@2x.jpg');
      }
    }
    @at-root html.no-touch &.about-us{
      &:hover{
        .content h3{
          color:$red;
          transition: $trance-in;
        }
      }
      .content h3{
        transition: $trance-out;
      }
    }

    .wrapper{
      overflow: hidden;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;
      @include box-sizing(border-box);
      @media (min-width: $break-xsmall) {
        padding: 0 $boxPadding;
      }
      .pattern {
        position: relative;
        cursor: pointer;
        height: 100%;
        background-size: 600px 400px;
        @media (min-width: $break-small) {
          background-size: 1200px 800px;
        }
      }
    }
    .description{
      cursor: pointer;
      //pointer-events: none;
      position: relative;
      left: 50%;
      @include box-sizing(border-box);
      @include transform(translate(-50%, 0));
      padding: $boxPadding * 5 $boxPadding-mobile;
//      max-width: 324px;
//      max-height: 324px;
      @media (min-width: $break-xsmall){
        padding: $boxPadding $boxPadding;
        height: 48.8vw;
        width: 48.8vw;
        min-width: 300px;
        min-height: 300px;
      }
      @media (min-width: $break-medium){
        height: 34.4vw;
        width: 34.4vw;
        max-height: 350px;
      }
      @media (min-width: $max-width-fontsize){
        width: $max-width-fontsize * 0.344;
        height: $max-width-fontsize * 0.344;
      }
      .content{
        height: 100%;
        background-color: $lightergrey;
        .center {
          padding: 50px 8vw;
          @include transform(translateY(0));
          h2 {
            @extend .title;
          }
          h3 {
            @extend .header;
          }
          a {
            @extend .title;
          }
          @media (min-width: $break-xsmall){
            padding: 0 3vw;
            @include transform(translateY(-50%));
          }
          @media (min-width: $max-width-frontpage){
            padding: 0 32px;
//            h3 {
//              font-size: 41px;
//            }
          }
        }
      }

    }
  }
  &.grid{
    li{
      @media (min-width: $break-medium) {
        width: 50%;
        float: left;
        &:nth-of-type(odd) {
          .wrapper {
            padding: 0 $boxPadding / 2 0 $boxPadding;
          }
        }
        &:nth-of-type(even){
          .wrapper{
            padding: 0 $boxPadding 0 $boxPadding / 2;
          }
        }
      }
    }
  }
}