//////// Global ////////

@import 'normalize';
@import 'mixins';
@import 'flexbox';
@import 'reset';
@import 'font';
@import 'base';
@import 'animations';
@import 'sprite-animations';

//////// Modules ////////
@import 'modules/menu';
@import 'modules/simple-header';
@import 'modules/intro';
@import 'modules/image';
@import 'modules/video';
@import 'modules/video-advanced';
@import 'modules/description';

@import 'modules/next-thing';
@import 'modules/pattern';
@import 'modules/big-words';
@import 'modules/column';
@import 'modules/column-two';
@import 'modules/multi';
@import 'modules/triple';
@import 'modules/object';
@import 'modules/contact-us';
@import 'modules/links';
@import 'modules/loader';

//@import 'fx';

//////// Frontpage ////////
.page.frontpage{
  @import 'frontpage/base';
  .part{
    @import 'frontpage/project';
    @import 'frontpage/articles';
    @import 'frontpage/about';
  }
  @import 'frontpage/description-box';
  margin-bottom: -$boxPadding/2;
  @media (min-width: $break-xsmall){
    margin-bottom: -$boxPadding;
  }
}

//////// Project Overview ////////

#projects.page{
  @import 'projects/base';
}

//////// Project ////////
.page.project{
  @import 'project/base';
  >article{
//    @import 'project/description';
//    @import 'project/process';
//    @import 'project/casefilm';
//    @import 'project/object';
    @import 'project/interactive';
//    @import 'project/image';
//    @import 'project/multi';
  }
  margin-bottom: -$boxPadding/2;
  @media (min-width: $break-xsmall){
    margin-bottom: -$boxPadding;
  }
}

//////// Article Overview ////////

//////// Article ////////
.page.article{
  @import 'article/base';
  @import 'article/picture-header';
  @import 'article/article';
}

//////// About ////////
.page.about{
  @import 'about/base';
  @import 'about/item';
}