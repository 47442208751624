&.project{
  background-size: cover;
  cursor: pointer;
  &.intro{
    cursor: auto;
    canvas{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
  .wrapper{
    height: 100%;
  }
  @media (min-width: $break-xsmall){
    &.intro{
      height: 100vh;
    }
  }
  @media (min-width: $break-small){
    .wrapper{
      max-width: $break-xmedium;
      @include hCenter(relative);
      img{
        position: relative;
        left: 66.666%;
        top: 50%;
        transform: translate(-50% -50%);
        height: 100%;
        max-height: 100%;
        min-width: 0;
        min-height: 0;
      }
    }
  }
}