$max-width-article: 876px;
.module.column {
  max-width: $max-width-article;
  @include hCenter(relative);
  padding-bottom: $boxPadding * 2;
  .wrapper {
    padding: 0 $boxPadding-mobile;
    > img {
      @include box-sizing(border-box);
      padding-bottom: $boxPadding-mobile;
      width: 100%;
    }
  }
  p, > a {
    svg {
      top: 57%;
    }
  }
  .text {
    @extend .paragraph-medium;
    margin-top: 0;
    margin-bottom: 34px;
    &:last-of-type{
      margin-bottom: 0px;
    }
    &.no-break {
      margin-bottom: 0;
    }
  }
  ul {
    margin-bottom: $boxPadding * 1.3;
    padding-left: $boxPadding * 0.9;
    &:last-child{
      margin: 0;
    }
  }
  .link, a {
    fill: $blue;
    color: $blue;
  }
  blockquote {
    text-align: center;
    font-size: 29px;
    margin: $boxPadding * 2 0;
  }
  @media (min-width: $break-xsmall) {
    .wrapper {
      padding: 0 $boxPadding;
      > img {
        padding-bottom: $boxPadding;
      }
    }
  }
  @media (min-width: $break-small) {
    .wrapper {
      padding: 0 $boxPadding * 3;
    }
  }
  .circle-icon {
    display: none;
    @media (min-width: $break-small) {
      display: block;
      width: 75px;
      height: 125px;
      float: left;
      @include shape-outside(circle($boxPadding * 2 at -50% 50%));
      @include shape-margin($boxPadding);
      @include transform(translateX(-150%));
      img {
        @include center(absolute);
        left: 83%;
        height: 53%;
      }
      svg{
        height: 100%;
      }
      &.right {
        width: 125px;
        float: right;
        @include shape-outside(circle($boxPadding * 2 at 80% 50%));
        @include transform(translateX($boxPadding * 2));
        img {
          left: 50%;
        }
      }
      &.big{
        width: 108px;
        height: 230px;
        @include shape-outside(circle(108px at -50% 50%));
        &.right{
          width: 180px;
          @include shape-outside(circle(108px at 50% 50%));
        }
      }
      &.mask{
        img{
          border-radius: 50%;
          height:100%;
        }
      }
    }
  }
}