&.articles{
  background-color: white;
  list-style: none;
  @include clearfix();
//  padding-bottom: 0;
//  @media(min-width: $break-xsmall) {
//    padding-bottom: $boxPadding / 2;
//  }
  padding: 0 $boxPadding / 2;
  li{
    @include box-sizing(border-box);
    width: 100%;
    @media(min-width: $break-xsmall) {
      width: 50%;
      float: left;
      padding: 0 $boxPadding / 2;
    }
    @media(min-width: $break-medium) {
      width: 33.333%;
      //width: calc(100% / 3);
    }
//    @media(min-width: $break-xmedium) {
//      width: 25%;
//    }
    &:nth-of-type(2){
      display: none;
      @media(min-width: $break-xsmall){
        display: block;
      }
    }
    &:nth-of-type(3){
      display: none;
      @media(min-width: $break-medium){
        display: block;
      }
    }
//    &:nth-of-type(4){
//      display: none;
//      @media(min-width: $break-xmedium){
//        display: block;
//      }
//    }
    .container{
      cursor: pointer;
      @at-root html.no-touch &{
        &:hover{
          & .description .spacing h3{
            color: $blue;
            transition: color 150ms linear;
          }
        }
        .description .spacing h3{
          transition: color 250ms linear;
        }
      }
    }
    img{
      display: block;
      width: 100%;
      //height: 230px; //TODO: FIX!
    }
    .description{
      background-color: $lightgrey;
      transition: background-color 230ms linear;
      @media(min-width: $break-xsmall) {
        height: 33vw;
      }
      @media(min-width: $break-medium) {
        height: 25vw;
      }
      @media (min-width: $max-width-frontpage){
        height: $max-width-frontpage * 0.25;
      }
      .center{
        @media (max-width: $break-xsmall - 1px){
          @include transform(translateY(0));
          padding: 12vw 8vw;
        }
        h2{
          @extend .title;
          margin: 0;
        }
        span{
          @extend .title;
        }
        .spacing{
          @media(min-width: $break-xsmall) {
            max-height: none;
            height: 15vw;
          }
          //@media(min-width: $break-medium) {
          //  height: 15vw;
          //}
          @media (min-width: $max-width-frontpage){
            height: $max-width-frontpage * 0.15;
          }
          h3{
            @include vCenter(relative);
            @extend .header;
            @media (max-width: $break-xsmall - 1px){
              margin-top: 12px;
              margin-bottom: 7px;
              top: 0;
              @include transform(translateY(0));
            }
            @media (min-width: $break-medium){
              font-size: 3.9vw;
            }
//            @media (min-width: $break-xmedium){
//              font-size: 3.2vw;
//            }
            @media (min-width: $max-width-fontsize){
              font-size: $max-width-fontsize * 0.039;
            }
          }
        }
        a{
          @extend .title;
        }
      }
    }
  }
}
