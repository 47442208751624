nav#back-button{
  position: fixed;
  z-index: 500;
  top: $boxPadding * 3;
  left: $boxPadding * 3;
  transition: transform 0.8s cubic-bezier($easeOutQuint);
  transform: translate(-50%, -50%) scale(0.01) rotate(100deg);
  width: 70px;
  height: 70px;
  cursor: pointer;
  .bg{
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $dark;
    opacity: 0.1;
    transition: transform 0.3s cubic-bezier($easeOutQuint);
  }
  >svg{
    position: relative;
    left: 48%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    fill: white;

  }
  &.shown{
    @media (min-width: $break-small) {
      transition: transform 0.8s 0.6s cubic-bezier($easeOutQuint);
      transform: translate(-50%, -50%);
      &.small {
        .bg {
          transform: scale(0.7);
        }
      }
    }
  }
  &.hidden{
    transition: transform 0.8s cubic-bezier($easeOutQuint);
    transform: translate(-50%, -50%) scale(0.01) rotate(100deg);
  }
  html.no-touch &{
    &:hover {
      cursor: pointer;
      .bg{
        transform: scale(1.1);
      }
    }
  }
}


nav#menu-button{
  position: fixed;
  z-index: 500;
  width: 100px;
  height: 100px;
  cursor: pointer;
  right: 0;
  #bg{
    @include center(absolute);
    fill: $dark;
    opacity: 0.1;
    width: 55px;
    height: 55px;
    transition: transform 0.4s cubic-bezier($easeOutQuint);
  }
  #tagline{
    position: relative;
    display: none;
    transition: transform 0.4s cubic-bezier($easeOutQuint);
    #dot{
      fill: white;
    }
    text{
      fill: white;
      font-size:12px;
      font-family: $font-paragraph;
      font-weight: 700;
      letter-spacing: 0.077em;
      html.safari &{
        letter-spacing: -0.009em;
      }
      html.ipad &{
        letter-spacing: 0.032em;
      }
    }
  }
  .rotate{
    width: 100%;
    height:100%;
    position:relative;
  }
  .burger{
    width: 29px;
    height: 2.5px;
    background-color: white;
    will-change: transform;
  }
  html.no-touch &{
    &:hover{
      #bg{
        transform: translate(-50%, -50%) scale(1.06);
      }
      #tagline{
        transform: scale(1.03);
      }
    }
  }
  &.open, &.shown{
    .rotate{
      transform: rotate(90deg);
    }
  }
  .rotate{
    transition: transform 0.4s cubic-bezier($easeOutBack1);
  }
  .burger{
    @include center();
    transition: transform 0.4s cubic-bezier($easeOutBack1), top 0.4s cubic-bezier($easeOutBack1);
    &.middle{
      @include center();
    }
    &.top{
      top: 43%;
    }
    &.bottom{
      top: 57%;
    }
  }
  @media (min-width: $break-small) {
    top: $boxPadding * 1.5;
    right: $boxPadding * 1.5;
    #bg{
      width: 100%;
      height: 100%;
    }
    #tagline{
      display: block;
    }
    .burger{
      transition: transform 0.25s cubic-bezier($easeOutBack1), top 0.25s cubic-bezier($easeOutBack1);
      width: 37px;
      height: 3px;
      &.top{
        top: 40%;
      }
      &.bottom{
        top: 60%;
      }
    }
    html.no-touch &{
      &:hover{
        #bg{
          transform: translate(-50%, -50%) scale(1.255);
        }
        #tagline{
          transform: scale(1.145);
        }
        .middle{
          transform: translate(-50%, -50%) scaleX(1.17);
        }
        .top{
          transform: translate(-50%, -170%);
        }
        .bottom{
          transform: translate(-50%, 70%);
        }
      }
    }
  }
  &.open, &.shown{
    .middle{
      transform: translateX(-50%) translateY(-50%) rotate(45deg) scaleX(0);
    }
    .top{
      top: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(135deg);
    }
    .bottom{
      top: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(225deg);
    }
    html.no-touch &{
      &:hover{
        #bg{
          transform: translate(-50%, -50%) scale(0.9);
        }
        #tagline{
          transform: scale(0.85);
        }
        .middle{
          transform: translateX(-50%) translateY(-50%) rotate(45deg) scaleX(0);
        }
        .top{
          top: 50%;
          transform: translateX(-50%) translateY(-50%) rotate(135deg) scaleX(0.8);
        }
        .bottom{
          top: 50%;
          transform: translateX(-50%) translateY(-50%) rotate(225deg) scaleX(0.8);
        }
      }
    }
  }
}

.site{
  min-height: 100vh;
  @media (min-width: $break-small) {
    padding-bottom: 100vh;
  }
}

footer.menu, .mobile-nav{
  position: relative;
  width: 100%;
  .height{
    position: relative;
    background-color: white;
    box-sizing: border-box;
    padding: $boxPadding-mobile;
    h2{
      font-family: $font-paragraph;
      font-size: 10px;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 1em;
      color: $dark;
      padding-bottom: $boxPadding * 2;
      padding-top: $boxPadding * 2;
      @media (min-width: $break-small){
        padding-top: 0;
      }
    }
    a, h3{
      font-family: $font-paragraph;
      font-size: 18px;
      font-weight: 400;
      line-height: 1em;
      position: relative;
      padding-bottom: $boxPadding;
      color: $dark;
      display: inline-block;
      svg{
        fill: $dark;
      }
      span{
        display: inline-block;
        font-size: 10px;
        font-weight: 500;
        line-height: 1em;
        text-transform: uppercase;
        margin-bottom: $boxPadding * 2;
      }
      &:hover{
        fill: $blue !important;
        color: $blue !important;
      }
    }
    >div, >nav{
      text-align: center;
      height: 100%;
    }
    .contact{
      background-color: $lightgrey;
    }
    nav{
      background-color: $lightergrey;
      .molamil-logo{
        svg{
          margin-top: $boxPadding * 2;
        }
        @media (min-width: $break-small) {
          position: absolute;
          left: 50%;
          svg{
            transform: translateX(-50%);
          }
        }
      }
      ul{
        list-style: none;
        padding: 0;
        padding-bottom: 34px;
        li{
          display: block;
        }
      }
    }
    .social{
      background-color: $lightergrey;
      a{
        margin-bottom: $boxPadding * 2;
        padding-bottom: 0;
        svg{
          transition:$link-transition-out;
        }
        @at-root html.no-touch &:hover{
          svg{
            transition:$link-transition-in;
            fill:$blue !important;
          }
        }
      }
    }
    .content{
      svg{
        fill: $dark;
      }
    }
  }
  nav .content,
  .contact .content{
    ul li,
    &{
      a{
        $padding:4px;
        padding:$padding;
        margin-top:$boxPadding/2 - $padding;
        margin-bottom:$boxPadding/2 - $padding;
        &.active{
//          font-weight: bold;
//          &:after{
//            content:"";
//            height:4px;
//            background-color: $lighterblue;
//            opacity: 1 !important;
//            width:100%;
//            position: absolute;
//            display: inline-block;
//            top:103%
//          }
        }
      }
    }
  }
  @media (min-width: $break-xsmall) {
    .height{
      padding: $boxPadding;
    }
  }
  @media (min-width: $break-small) {
    position: absolute;
    pointer-events: none;
    margin-top: -100vh;
    height: 100vh;
    //transition: transform 0.7s cubic-bezier($easeOutQuint);
    //transform: translateY(-100%);
    overflow: hidden;
    &.wrapper{
      padding: 0;
    }
    .height {
      pointer-events: auto;
      display: flex;
      flex-direction: row;
      //transform: translateY(25%);
      height: 100vh;
      &.shown{
        transform: translateY(0%);
      }
      .content{
        @include vCenter(relative);
        height: 350px;
        padding-top: $boxPadding * 4;
      }
      > div, > nav {
        width: 33.333%;
        width: calc(100% / 3);
      }
      .contact{
        order: 1;
        .content{
          //padding-top: $boxPadding * 2;
        }
      }
      nav{
        order: 2;
        .content{
          //padding-top: $boxPadding * 3.5;
        }
      }
      .social{
        order: 3;
        background-color: $lightgrey;
        .content{
          //padding-bottom: $boxPadding * 1;
        }
      }
    }
    &.open{
      position: fixed;
      top: 0;
      margin-top: 0;
      //transform: translateY(0);
      z-index: 190;
    }
  }
}
.mobile-nav{
  position: fixed;
  z-index: 195;
  height: 100%;
  svg{
    margin-top: $boxPadding;
  }
  transition: transform 0.7s cubic-bezier($easeOutQuint);
  &.hidden{
    transform: translateY(110%);
  }
  .height{
    height: 100%;
    ul{
      padding-top: $boxPadding !important;
    }
  }
  @media (min-width: $break-small){
    display: none;
  }
}