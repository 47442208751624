@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);

    @if $width == cover {
      background-size: cover;
    }
    @else {
      background-size: $width $height;
    }

  }
}

// transform
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

// center
@mixin hCenter($position:absolute){
  position: $position;
  left: 50%;
  @include transform(translateX(-50%));
}

@mixin vCenter($position:absolute){
  position: $position;
  top: 50%;
  @include transform(translateY(-50%));
}

@mixin center($position:absolute){
  position: $position;
  top: 50%;
  left: 50%;
  @include transform(translate(-50%,-50%));
}

//transform origin
@mixin transform-origin ($origin) {
  -moz-transform-origin: $origin;
  -o-transform-origin: $origin;
  -ms-transform-origin: $origin;
  -webkit-transform-origin: $origin;
  transform-origin: $origin;
}

@mixin clip-path($details...){
  -webkit-clip-path: $details;
  clip-path: $details;
}

//transition
@mixin transition($transition...) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -ms-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

@mixin transitionTransform($details...) {
  -webkit-transition: -webkit-transform $details;
  -moz-transition: -moz-transform $details;
  -ms-transition: -ms-transform $details;
  -o-transition: -o-transform $details;
  transition: transform $details;
}

@mixin box-sizing($box-model) {
  -webkit-box-sizing: $box-model; // Safari <= 5
  -moz-box-sizing: $box-model; // Firefox <= 19
  box-sizing: $box-model;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

@mixin perspective($value){
  -webkit-perspective: $value;
  perspective: $value;
}

@mixin perspective-origin($value){
  -webkit-perspective-origin: $value;
  perspective-origin: $value;
}

@mixin clearfix() {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

//@mixin keyframes($animation-name) {
//  @-webkit-keyframes #{$animation-name} {
//    @content;
//  }
//  @-moz-keyframes #{$animation-name} {
//    @content;
//  }
//  @-ms-keyframes #{$animation-name} {
//    @content;
//  }
//  @-o-keyframes #{$animation-name} {
//    @content;
//  }
//  @keyframes #{$animation-name} {
//    @content;
//  }
//}
//
//@mixin animation($animate...) {
//  $max: length($animate);
//  $animations: '';
//
//  @for $i from 1 through $max {
//    $animations: #{$animations + nth($animate, $i)};
//
//    @if $i < $max {
//      $animations: #{$animations + ", "};
//    }
//  }
//  -webkit-animation: $animations;
//  -moz-animation:    $animations;
//  -o-animation:      $animations;
//  animation:         $animations;
//}

@mixin shape-outside($value){
  -webkit-shape-outside: $value;
  shape-outside: $value;
}

@mixin shape-margin($value){
  -webkit-shape-margin: $value;
  shape-margin: $value;
}

/* animation mixing
keyframe animation
@include animation('animation-name .4s 1')*/

@mixin animation($animate...) {
  $max: length($animate);
  $animations: '';

  @for $i from 1 through $max {
    $animations: #{$animations + nth($animate, $i)};

    @if $i < $max {
      $animations: #{$animations + ", "};
    }
  }
  -webkit-animation: $animations;
  -moz-animation:    $animations;
  -o-animation:      $animations;
  animation:         $animations;
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    $browser: '-webkit-'; @content;
  }
  @-moz-keyframes #{$animationName} {
    $browser: '-moz-'; @content;
  }
  @-o-keyframes #{$animationName} {
    $browser: '-o-'; @content;
  }
  @keyframes #{$animationName} {
    $browser: ''; @content;
  }
} $browser: null;

/* Animation usage example:
@include keyframes(animation-name) {
  0% {
    #{$browser}transform: translate3d(100%, 0, 0);
  }
  100% {
    #{$browser}transform: translate3d(0%, 0, 0);
  }
}

.new-class {
  @include animation('animation-name 5s linear');
}*/