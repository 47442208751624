$max-width: 1100px;

.header{
  font-family: $font-header;
  margin-bottom: 17px;
  font-size: 45px;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 500;
  color: $dark;
  text-align: center;
  @media (min-width: $break-small){
    font-size: 60px;
  }
  @media (min-width: $break-smedium){
    font-size: 70px;
  }
  //@media (min-width: $break-medium){
  //  font-size: 90px;
  //}
  //@media (min-width: 1100px){
  //  font-size: 110px;
  //}
}

.deck{
  font-family: $font-paragraph;
  font-size: 26px;
  font-weight: 500;
  line-height: 1.4em;
  color: $dark;
}

.title{
  @extend .next-title;
}

.subtitle{
  @extend .next-subtitle;
}

.paragraph-text {
  font-size: 21px;
  &.dropcap {
    &:first-letter {
//      font-size: 70px;
    }
  }
}

&.simple-header{
  header {
    h2 {
      @extend .header;
    }
    span, a {
      @extend .subtitle;
    }
//    margin-bottom: $boxPadding * 2;
  }
}