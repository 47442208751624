$project-max: 1500px;
.title{
  @extend .next-title;
  font-size: 50px;
  margin-bottom: 30px;
  color: white;
}

.subtitle{
  @extend .next-subtitle;
  margin: 0;
  color: white;
}
ul{
  padding: 0;
}

li{
  cursor: pointer;
  position: relative;
  display:block;
  min-height: 300px;
  overflow: hidden;
  @media (min-width: $break-small){
    height: 40vh;
    max-height: 450px;
  }
}
.background-color-mobile, .background-color-desktop{
  height:100%;
}
.background-color-desktop {
  @include hCenter(relative);
  max-width: $project-max;
  overflow: visible;

  @media (min-width: $break-small) {
    background-color: transparent !important;
  }
}
.description{
  box-sizing: border-box;
  height: 100%;
}
.content{
  height:100%;
  min-height: 0;
  max-height: none;
}
.background-fill img{
  height: auto;
}
a{
  color: white;
}

@media (max-width: $break-small - 1) {
  .content{
    padding: $boxPadding 0;
  }
  .description{
    padding: $boxPadding;
  }
  .object, img{
    padding: 0;
  }
  .background-fill{
    max-height: 540px;
    .background-color-desktop{
      position: absolute;
      bottom: 0;
      height: auto;
      width: 100%;
    }
  }
}
@media (max-width: $break-xsmall - 1) {
  .description{
    padding: $boxPadding-mobile;
  }
}

@media (min-width: $break-small) {
  .child {
    position: absolute;
    //max-width: 863px;
    height: 100%;
    width: 100%;
    left: 0;
    img {
      height: 100%;
      width: auto;
      max-width: none;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .background-fill{
    img{
      height: auto;
      width: 100%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
li {
  picture{
    html.no-touch &{

      &:hover{
      }
    }
  }
  @include transitionTransform(0.4s cubic-bezier($easeOutQuint));
}

li.hidden{
  @include transform(translateY(1500px));
  @include transform(translateY(100vh));
}

html.ie10 &, html.ie11 &{
  .background-color-desktop{
    display: block;
    .object{
      display: block;
      position: absolute;
      height: 100%;
      padding-left: 455px;
    }
  }
}