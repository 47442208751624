.module.description{
  position:relative;
  width: 100%;
  padding-bottom: $boxPadding;
  @media (min-width: $break-small){
    width: 50%;
    max-width: 455px;
    padding: $boxPadding;
    @include box-sizing(border-box);
  }
  .content{
    @media (max-width: $break-small - 1px) {
      .project & {
        background-color: transparent !important;
      }
    }
    .center{
      text-align: center;
      padding: 0 $boxPadding;
      h2{
        @extend .title;
      }
      h3{
        @extend .subtitle;
      }
      p{
        @extend .paragraph-small;
        font-size: 18px;
        margin-bottom: 0;
        @media (min-width: $break-small) {
          margin-bottom: 18px;
        }
      }
    }
    @media (min-width: $break-small) {
      height: 56.25vw;
      min-height: 350px;
      max-height: 700px;
      .center{
        @include vCenter(relative);
      }
    }
    a:hover {
      color: #fff !important;
    }
  }
  &.right{
    @media (min-width: $break-small){
      float: right;
    }
  }
}