&.picture-header >article{
  header{
    max-width: $max-width;
    @include hCenter(relative);
    padding: $boxPadding;
    padding-bottom: 0;
    img{
      width: 100%;
      //border-bottom: 11px solid $blue;
    }
    h2{
      @extend .header;
      max-width: 876px;
      @include hCenter(relative);
      padding: $boxPadding-mobile * 3 $boxPadding-mobile $boxPadding-mobile $boxPadding-mobile;
      @media (min-width: $break-xsmall){
        padding: $boxPadding * 2 $boxPadding $boxPadding * 1 $boxPadding;
      }
      @media (min-width: $max-width){
        padding: $boxPadding * 2.8 0 $boxPadding * 1.3 0;
      }
    }
    span{
      @extend .subtitle;
      @include hCenter(relative);
      display: inline-block;
      margin-bottom: $boxPadding-mobile;
      @media (min-width: $break-xsmall){
        margin-bottom: $boxPadding * 1.2;
      }
    }
  }
}