.next-title{
  font-family: $font-header;
  font-size: 35px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 17px;
  line-height: 1em;
  color: $dark;
}

.next-subtitle{
  font-family: $font-paragraph;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 17px;
  line-height: 1.7em;
  color: $dark;
  letter-spacing: 0.1em;
}

footer.next-thing{
  position: relative;
  padding: 0 $boxPadding-mobile $boxPadding-mobile $boxPadding-mobile;
  @media (min-width: $break-xsmall){
    padding: 0 $boxPadding $boxPadding $boxPadding;
  }
  picture{
    img{
      display: block;
      width:100%;
    }
  }
  .description{
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50%;
    width: 100%;
    @include box-sizing(border-box);
    padding: 0 $boxPadding-mobile * 2 $boxPadding-mobile * 2 $boxPadding-mobile * 2;
    @media (min-width: $break-xsmall){
      padding: 0 $boxPadding * 2 $boxPadding * 2 $boxPadding * 2;
    }
    @media (min-width: 600px){
      @include hCenter(absolute);
      top: 0;
      width: 30%;
      height: 100%;
      padding: $boxPadding 0 $boxPadding * 2 0;
    }
    .content{
      position:relative;
      height: 100%;
      background-color: #f3f3f3;
      .center{
        @include vCenter(relative);
        text-align: center;
        padding: 0 $boxPadding;
        h2{
          @extend .next-subtitle;
        }
        h3{
          @extend .next-title;
          font-size: 35px;
          @media (min-width: 600px){
            font-size: 30px;
          }
          @media (min-width: $break-small){
            font-size: 35px;
          }
          @media (min-width: 1140px){
            font-size: 50px;
          }
        }
        a{
          @extend .next-subtitle;
          display: block;
          text-decoration: none;
        }
      }
    }
  }
}