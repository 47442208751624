>article section.item{
  position: relative;
  .background {
    position: absolute;
    height: 170%;
    width: 100%;
    background-color: white;
  }
  &:nth-child(odd){
    .background{
      background-color: #fafafa;
    }
    .bubble{
      fill: white;
      .description{
        background-color: white;
      }
    }
  }
  .wrapper{
    padding: $boxPadding * 2 $boxPadding;
    .content{
      max-width: 540px;
      @include hCenter(relative);
      .description {
        position: relative;
        text-align: center;

        @include box-sizing(border-box);
        h3 {
          @extend .title;
          font-size: 45px;
          padding-bottom: 14px;
        }
        p {
          @extend .paragraph-small;
          font-size: 18px;
        }
        .list {
          @include clearfix();
          position: relative;
          left: 30%;
          ul {
            @extend .paragraph-small;
            font-size: 18px;
            text-align: left;
            list-style-image: url(../assets/ui/ul_dot.svg);
            padding-left: 11px;
          }
        }
        a:first-of-type {
          margin-top: 20px;
        }
        a, button {
          @extend .paragraph-small;
          font-size: 18px;
          padding: 0;
          background-color: transparent;
          border: none;

          display: inline-block;
          margin-bottom: $boxPadding;
          max-width: 260px;
          @media (min-width: $break-xsmall){
            margin-bottom: $boxPadding-mobile;
            max-width: none;
          }
        }
      }
    }
    .object{
      position: relative;
      padding-bottom: $boxPadding * 2;
      img{
        @include hCenter(relative);
        max-width: 100%;
        &.move36{
          @include transform(translateX(-36%) );
        }
      }
    }
    .contact-info {
      height: 0;
      .bubble{
        @include transform(translateY(0));
        @include transitionTransform( 0.7s cubic-bezier($easeOutQuint));
        &.hidden{
          @include transform(translateY($boxPadding * 7));
        }
        @media (min-width: $break-small){
          img{
            //@include transform(translateX(-200%) translateY(-50%));
            @include transitionTransform(0.5s 0.3s cubic-bezier($easeOutQuint));
          }
          .description{
            @include transition(padding 0.5s 0.3s cubic-bezier($easeOutQuint));
          }
          &.hidden{
            img{
              @include transform(translateX(-200%) translateY(-50%));
            }
            .description{
              padding: $boxPadding * 2 $boxPadding * 2 $boxPadding * 2;
            }
          }
        }
      }
    }
    @media (min-width: $break-xsmall){
      h3{
        padding-bottom: $boxPadding-mobile;
      }
    }
    @media (min-width: $break-small){
      h3{
        white-space: nowrap;
        padding-bottom: $boxPadding * 1.5;
      }
    }
    @media (min-width: $break-smedium){
      max-width: 980px;
      padding: 130px $boxPadding;
      @include hCenter(relative);
      .content{
        left: auto;
        @include transform(translateX(0));
        max-width: none;
        @include flexbox();
        @include flex-direction(row-reverse);
        .description{
          width: 60%;
          text-align: left;
          p{
            max-width: 465px;
          }
          .list{
            left: auto;
            ul{
              white-space: nowrap;
              float: left;
              padding-right: 50px;
            }
          }
        }
        .object{
          padding: 0;
          width: 40%;
          img{
            @include center(absolute);
            &.move36{
              @include transform(translateX(-36%) translateY(-50%));
            }
          }
        }
      }
    }
  }
  &:first-of-type{
    .wrapper{
      padding-top: $boxPadding * 4;
    }
  }
}