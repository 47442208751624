.module.video{
  html.android &{
    display: none;
  }
  .aspect {
    overflow: hidden;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
  }
  .aspect-video{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }
  iframe, video{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }
  &.description-box {
    .aspect{
      .description{
        position: absolute;
        height: 100%;
        .content{
          min-height: 0;
          max-height: none;
          height: 100%;
        }
      }
    }
    @media (max-width: $break-small - 1px) {
      .aspect {
        height: auto;
        padding-bottom: 0;
        .aspect-video {
          position: relative;
          padding-bottom: 56.25%;
          height: 0;
        }
        .description {
          padding-top: $boxPadding;
          position: relative;
        }
      }
    }
  }
}