section.big-words{
  height: 70vh;
  min-height: 450px;
  position: relative;
  &:nth-of-type(2){
    margin-top: 35px;
  }
  .background{
    background-color: #f8f8f8;
    position: absolute;
    overflow: hidden;
    height: 100%;
    width: 100%;
    picture{
      width: 100%;
      height: 100%;
    }
    img{
      @include center(relative);
      min-width: 100%;
      min-height: 100%;
      &.bottom{
        @include transform(translateX(-50%) translateY(-100%));
        top: 100%;
      }

    }
  }
  .content{
    @include vCenter(relative);
    h3{
      font-family: $font-header;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 1em;
      font-size: 30px;
      text-align: center;
      margin-bottom: $boxPadding * 2.2;
    }
    h4{
      font-family: $font-header;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 1em;
      font-size: 60px;
      text-align: center;
    }
    p{
      max-width: 620px;
      @include hCenter(relative);
      @extend .paragraph-small;
      font-size: 18px;
      padding: 0 $boxPadding;
      text-align: center;
    }
    @media (min-width: $break-xsmall){
      h3 {
        font-size: 35px;
        margin-bottom: $boxPadding * 3;
      }
      h4{
        font-size: 90px;
      }
    }
    @media (min-width: $break-small){
      h3 {
        font-size: 35px;
        margin-bottom: $boxPadding * 3.5;
      }
      h4{
        font-size: 160px;
      }
    }
  }
  &.white{
    h3, h4, p{
      color: white;
    }
  }
}