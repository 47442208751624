>article{
  @include transitionTransform(0.5s cubic-bezier($easeOutQuint));
  hr{
    border: 0;
    height: 3px;
    width: 35px;
    background-color: $dark;
    display: inline-block;
    margin-top: 0;
    margin-bottom: $boxPadding * 1.9;
  }
  footer{
    @include box-sizing(border-box);
    padding: 0 $boxPadding-mobile $boxPadding * 2 $boxPadding-mobile;
    @media (min-width: $break-xsmall){
      padding: 0 $boxPadding $boxPadding * 2 $boxPadding;
    }
    @media (min-width: $break-small){
      padding: 0 $boxPadding * 3 $boxPadding * 2 $boxPadding * 3;
    }
  }
  .column{
    h3{
      @extend .title;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  &.hidden{
    @include transform(translateY(1500px));
    @include transform(translateY(100vh));
  }
}
&.simple-header {
  >article{
    background-color: #fff;
    margin-top: 50%;
    @media (min-width: $break-xsmall){
      margin-top: 40vh;
    }
  }
}
&.light-text{
  >article section.column{
    max-width: 690px;
    padding: $boxPadding * 2 + 8 0;
    .text, >a, blockquote, li{
      font-size: 17px;
      &.dropcap{
        &:first-letter{
          padding-top: 0.315em;
          padding-right: 0.04em;
        }
      }
    }
    h3{
      @extend .paragraph-medium;
      font-weight: 600;
      font-size: 13px;
    }
  }
}