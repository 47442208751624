$project-max: $break-large;
.header{
  font-family: $font-header;
  margin-bottom: 17px;
  font-size: 45px;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 500;
  color: $dark;
  max-width: 480px;
  @media (min-width: $break-small){
    max-width: 600px;
    font-size: 50px;
  }
  @media (min-width: $break-medium){
    max-width: 770px;
    font-size: 70px;
  }
}

.title{
  @extend .next-title;
}

.subtitle{
  @extend .next-subtitle;
}

>article {
  >header{
    h2{
      @extend .header;
    }
    a, span{
      @extend .subtitle;
    }
  }
  > section {
    position: relative;
    padding: 0 $boxPadding-mobile $boxPadding-mobile $boxPadding-mobile;
    @media (min-width: $break-xsmall) {
      padding: 0 $boxPadding $boxPadding $boxPadding;
    }
    @media (min-width: $break-medium) {
      @include hCenter(relative);
    }
    .background-color-mobile {
      @media (min-width: $break-small) {
        padding: 0;
        background-color: transparent !important;
      }
    }
    .background-color-desktop {
      @media (max-width: $break-small - 1px) {
        background-color: transparent !important;
      }
    }
  }
}