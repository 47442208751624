.module.image {
  > img{
    display:block;
    width: 100%;
  }
  .background{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include box-sizing(border-box);
    padding: $boxPadding-mobile $boxPadding-mobile $boxPadding $boxPadding-mobile;
    @media (min-width: $break-xsmall) {
      padding: $boxPadding;
    }
    @media (min-width: $break-small){
      position: absolute;
      padding: 0 $boxPadding $boxPadding $boxPadding;
    }
    .wrapper{
      position:relative;
      width:100%;
      >img{
        @include hCenter(relative);
        height: 40vw;
      }
      @media (min-width: $break-small) {
        height: 100%;
        overflow: hidden;
        > img {
          height: 100%;
          @include center(absolute);
        }
      }
    }
  }
  &.background-fill{
    .background-color-desktop{
      position:relative;
      overflow: hidden;
    }
    picture {
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
      @include box-sizing(border-box);
      padding: $boxPadding-mobile;
      @media (min-width: $break-xsmall){
        padding: $boxPadding;
      }
      @media (min-width: $break-small){
        right: 0;
        position: absolute;
        //height: 100%;
        width: auto;
        height:100%;
        min-width: 100%;
        min-height: 100%;
        padding: 0;
      }
    }
    &.right{
      &:not(.center){
        img{
          left: 0;
        }
        .module.description{
          float: right;
        }
      }
    }
    &.center{
      &:not(.right){
        img {
          @media (min-width: $break-small){
            transform: translateX(15%);
            right: 0;
          }
          @media (min-width: $break-medium){
            top: 50%;
            transform: translate(-65.7%, -50%);
            right: auto;
            left: calc((100% - 430px) / 2 + 430px);
          }
          @media (min-width: $break-xmedium){
            transform: translate(0, -50%);
            right: 0;
            left: auto;
            height: auto;
          }
        }
      }
      &.right{
        img {
          @media (min-width: $break-small){
            transform: translateX(-15%);
            left: 0;
          }
          @media (min-width: $break-medium){
            top: 50%;
            transform: translate(65.7%, -50%);
            left: auto;
            right: calc((100% - 430px) / 2 + 430px);
          }
          @media (min-width: $break-xmedium){
            transform: translate(0, -50%);
            left: 0;
            right: auto;
            height: auto;
          }
        }
      }
    }
  }
}