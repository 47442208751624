@keyframes scroll-tablet {
  0% { transform: translateY(0); }
  100% { transform: translateY(-84%);  }
}

@keyframes scroll-mobile {
   0% { transform: translateY(0); }
   100% { transform: translateY(-84%); }
}

@keyframes carousel-rotate {
  0% { transform: rotateY(0deg); }
  35% { transform: rotateY(0deg); }
  65% { transform: rotateY(180deg); }
  100% { transform: rotateY(180deg); }
}

@keyframes frontpage-description-box-hover {
  0%   { clip-path: inset($boxPadding      $boxPadding / 2 $boxPadding     $boxPadding / 2); }
  25%  { clip-path: inset($boxPadding / 2  $boxPadding / 2 $boxPadding     $boxPadding / 2); }
  50%  { clip-path: inset($boxPadding / 2  0               $boxPadding     $boxPadding / 2); }
  75%  { clip-path: inset($boxPadding / 2  0               $boxPadding / 2 $boxPadding / 2); }
  100% { clip-path: inset($boxPadding / 2  0               $boxPadding / 2 0              ); }
}

@keyframes frontpage-description-box-nonhover {
  0%   { clip-path: inset($boxPadding / 2  0               $boxPadding / 2 0              ); }
  25%  { clip-path: inset($boxPadding / 2  0               $boxPadding / 2 $boxPadding / 2); }
  50%  { clip-path: inset($boxPadding / 2  0               $boxPadding     $boxPadding / 2); }
  75%  { clip-path: inset($boxPadding / 2  $boxPadding / 2 $boxPadding     $boxPadding / 2); }
  100% { clip-path: inset($boxPadding      $boxPadding / 2 $boxPadding     $boxPadding / 2); }
}

$link-transition-in: all 220ms linear;
$link-transition-out: all 220ms linear;


html.no-touch a:not(.active){
  &.icon{
    span,svg{
      transition:$link-transition-out;
    }
    &:hover{
      span{
        transition:$link-transition-in;
        color:#000 !important;
      }
      svg{
        transition:$link-transition-in;
        fill:#000 !important;
      }
    }
  }
  h3,
  &{
    transition: $link-transition-out;
  }
  &:hover,
  &:hover h3{
    transition: $link-transition-in;
    fill:#000 !important;
    color:#000 !important;
  }
}



//@include transform(translateY(0%))
//@include transform(translateY(-100%))