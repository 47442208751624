>section.interactive{
  canvas{
    display: block;
    width:100%;
    height:100%;
  }
  @media (max-width: $break-small - 1px){
    .aspect{
      position: relative;
      width:100%;
      height: 0;
      padding-bottom: 56.25%;
      canvas{
        position: absolute;
        top: 0;
      }
    }
  }
}