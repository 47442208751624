.module.triple{
  >.wrapper {
    width: 100%;
    display:block;
    @include clearfix();
    >.object {
      width: 100%;
      display: none;
      &:first-of-type{
        display: block;
      }
      @media (min-width: $break-xsmall) {
        width: 50%;
        float: left;
        @include box-sizing(border-box);
        &:nth-of-type(3n+1) {
          display: block;
          padding-right: $boxPadding / 2;
        }
        &:nth-of-type(3n+2) {
          display: block;
          padding-left: $boxPadding / 2;
        }
      }
      @media (min-width: $break-smedium) {
        width: 33.333%;
        width: calc(100% / 3);
        &:nth-of-type(3n+1) {
          padding-right: $boxPadding / 3 * 2;
        }
        &:nth-of-type(3n+2) {
          padding: 0 $boxPadding / 3;
        }
        &:nth-of-type(3n+3) {
          display: block;
          padding-left: $boxPadding / 3 * 2;
        }
        img {
          padding-bottom: 0;
        }
      }
      img {
        width: 100%;
      }
    }
  }
}