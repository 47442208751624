body, ul, h1, h2, h3, h4, h5, h6{
  margin: 0;
}

body{
  -webkit-font-smoothing: antialiased;
}

a{
  text-decoration: none;
}