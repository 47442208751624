.pattern {
  //display: none;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &.fixed {
    position: fixed;
    z-index: 100;
  }
}

#main-patterns {
  //display: none;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}