&.capabilities .theme-color{
  color: #37d42e !important;
  fill: #37d42e;
}

.header{
  font-family: $font-header;
  margin-bottom: 17px;
  font-size: 45px;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 500;
  color: $dark;
  max-width: 480px;
  @media (min-width: $break-small){
    max-width: 600px;
    font-size: 50px;
  }
  @media (min-width: $break-medium){
    max-width: 770px;
    font-size: 70px;
  }
}

.title{
  font-family: $font-header;
  font-size: 35px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 17px;
  line-height: 1em;
  color: $dark;
}

.subtitle{
  font-family: $font-paragraph;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 17px;
  line-height: 1em;
  color: $dark;
}

>article {
  > header {
    h2 {
      @extend .header;
    }
    a {
      @extend .subtitle;
    }
  }
  .next-thing{
    h2{
      @extend .subtitle;
    }
    h3{
      @extend .title;
    }
    a{
      @extend .subtitle;
    }
    background-color: #fff;
    &:first-of-type{
      padding: $boxPadding-mobile;
      @media (min-width: $break-xsmall){
        padding: $boxPadding;
      }
      @media (min-width: 600px){
        .description{
          padding: $boxPadding * 2 0;
        }
      }
    }
  }
}
>.menu{
  background-color: white;
}

