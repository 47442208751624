
//$break-xsmall: 320px; Iphone Portrait
$break-xsmall: 500px;
$break-small: 700px;
//$break-small: 750px; Ipad Portrait
$break-smedium: 770px;
$break-medium: 920px;
$break-xmedium: 1440px;
$break-large: 1500px;

$boxPadding: 25px;
$boxPadding-mobile: 10px;

$dark: #333333;
$lightgrey: #f4f4f4;
$lightergrey: #f9f9f9;
$blue: #34aed6;
$lightblue: #41b4da;
$lighterblue: #cee7f8;
$green: #2bd618;
$lightergreen: #aae9a3;
$yellow: #ffea01;
$lighteryellow: #f8f09a;
$red: #ff5757;
$lighterred: #f8baba;

$easeOutQuint: 0.230, 1.000, 0.320, 1.000;
$easeOutBack1: 0.630, 0.290, 0.170, 1.345;
$easeOutBack2: 0.175, 0.885, 0.500, 1.405;
$easeOutCirc: 0.785, 0.135, 0.150, 0.860;
$easeInOutCubic: 0.645, 0, 0.355, 1.000;
$easeInBackOutBack: 0.590, -0.450, 0.405, 1.290;

$font-header: "alternate-gothic-no-1-d", "BrandonText", sans-serif;
$font-paragraph: "brandon-grotesque", "BrandonText", sans-serif;
$font-paragraph-block: "BrandonText", sans-serif;

#templates {
  display: none;
}


#br-info {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  top: -1px;
  @media (min-width: $break-large) { // Adding a default on large
    &::after {
      content: "large";
    }
  }
  @media (max-width: $break-large) {
    &::after {
      content: "large";
    }
  }
  @media (max-width: $break-xmedium) {
    &::after {
      content: "xmedium";
    }
  }
  @media (max-width: $break-medium) {
    &::after {
      content: "medium";
    }
  }
  @media (max-width: $break-smedium) {
    &::after {
      content: "smedium";
    }
  }
  @media (max-width: $break-small) {
    &::after {
      content: "small";
    }
  }
  @media (max-width: $break-xsmall) {
    &::after {
      content: "xsmall";
    }
  }
}

body {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.page.frontpage {
  display: none; // Start on display: none as Zumo will swap this.
  //height: 300px;
}
.page>article>section{
  &.image, &.object, &.interactive, &.video, &.casefilm{
    max-width: $break-large;
  }
}

.layer{
  position: relative;
}

.paragraph-small{
  font-family: $font-paragraph, $font-paragraph-block;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.4em;
  color: $dark;
}

.paragraph-normal{
  font-family: $font-paragraph-block, $font-paragraph;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.55em;
  color: $dark;
  &.dropcap{
    &:first-letter{
      float: left;
      font-family: $font-header;
      font-size: 3.8em;
      padding-top: 0.25em;
      padding-right: 0.04em;
    }
  }
}

.paragraph-medium{
  font-family: $font-paragraph-block, $font-paragraph;
  font-size: 21px;
  font-weight: 400;
  line-height: 1.55em;
  color: $dark;
  &.dropcap{
    &:first-letter{
      float: left;
      font-family: $font-header;
      font-size: 3.35em;
      padding-top: 0.30em;
      padding-right: 0.04em;
    }
    &.hold-left{
      @media (min-width:$break-medium) {
        &:first-letter {
          margin-left: -0.6em;
        }
      }
    }
  }
}

a.icon, button.icon{
  position: relative;
  svg {
    position: absolute;
    @include transform(translateY(-50%));
    top: 11px;
  }
  span {
    padding-left: $boxPadding;
  }
  &.arrow-right{
    svg{
      top: 8px;
    }
  }
}


.page{
  overflow: hidden;
  &:not(.frontpage){
    position: relative;
  }
  &:not(.article) {
    > article {
      background-color: white;
    }
  }
}


.corner-logo-molamil{
  position: absolute;
  top: $boxPadding;
  left: $boxPadding;
  @include transitionTransform(0.5s cubic-bezier($easeOutQuint));
  @media (min-width: $break-small){
    top: $boxPadding * 2;
    left: $boxPadding * 2;
  }
  .hidden &{
    @include transform(translateX(-190%));
    @media (min-width: $break-small){
      @include transform(translateX(-200%));
    }
  }
}