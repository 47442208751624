#loader {
  z-index: 1000;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff url('../assets/loader.gif') no-repeat center center;
  background-size: 183px 20px;
  @media (min-width: $break-small) {
    background-size: 293px 32px;
  }
  img {
    position: absolute;
    top: -1px;
    left: -1px;
    width: 1px;
    height: 1px;
  }
}