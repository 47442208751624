#rocket-sprite {
  display: none;
  html.no-touch & {
    display: block;
    @include hCenter(absolute);
    bottom: 0;
    width: 104px;
    height: 119px;
    background-image: url('/assets/content/about/about-us/exploration_sprite.png');
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background-image: url('/assets/content/about/about-us/exploration_sprite@2x.png');
      background-size: 1456px 1309px;
    }
    background-position: 0 0;
    animation: rocket-sprite 4.5s steps(13) infinite; // steps = 14 items in a row - 1
    background-repeat: no-repeat;
    overflow: hidden;
  }
}

@keyframes rocket-sprite {
  0% {      background-position: 0 0}        9.090% {  background-position: -1352px 0}
  9.091% {  background-position: 0 -119px}   18.181% { background-position: -1352px -119px}
  18.182% { background-position: 0 -238px}   27.272% { background-position: -1352px -238px}
  27.273% { background-position: 0 -357px}   36.363% { background-position: -1352px -357px}
  36.364% { background-position: 0 -476px}   45.454% { background-position: -1352px -476px}
  45.455% { background-position: 0 -595px}   54.545% { background-position: -1352px -595px}
  54.546% { background-position: 0 -714px}   63.636% { background-position: -1352px -714px}
  63.637% { background-position: 0 -833px}   72.727% { background-position: -1352px -833px}
  72.728% { background-position: 0 -952px}   81.818% { background-position: -1352px -952px}
  81.819% { background-position: 0 -1071px}  90.909% { background-position: -1352px -1071px}
  90.910% { background-position: 0 -1190px}  100% {    background-position: -1352px -1190px}
}