$max-width-frontpage: $break-xmedium;
$max-width-fontsize: $break-xmedium;

.grey{
  background-color: $lightgrey;
  margin: $boxPadding / 2 0;
  @media (min-width: $break-xsmall){
    margin: $boxPadding 0;
  }
}

.header{
  font-family: $font-header;
  margin-bottom: 17px;
  font-size: 35px;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: 500;
  color: $dark;
  @media (min-width: $break-xsmall){
    font-size: 5vw;
  }
  @media (min-width: $break-medium){
    font-size: 3.9vw;
  }
  @media (min-width: $max-width-fontsize){
    font-size: $max-width-fontsize * 0.039;
  }
}

.title{
  @extend .next-subtitle;
}

.part{
  height: 100vh;
  overflow: hidden;
  position: relative;

  &.wrapper{
    max-width: $max-width-frontpage;
    @include box-sizing(border-box);
    padding: $boxPadding-mobile;
    @include hCenter(relative);
    height: auto;
    @media (min-width: $break-xsmall){
      padding: $boxPadding / 2;
    }
  }
  .description{
    text-align: center;
    .center{
      @include vCenter(relative);
      padding: 0 8vw;
      @media (min-width: $break-xsmall){
        padding: 0 3vw;
      }
    }
  }
  &.project{
    //picture{
    //  width: 100%;
    //  height: 100%;
    //}
    img{
      width: 100%;
      @media (min-width: $break-xsmall) {
        @include center(relative);
      }
      @media (min-width: $break-small) {
        width: auto;
        min-width: 100%;
        min-height: 100%;
      }
    }
  }
  @media (min-width: $break-xsmall){
    height: 75vh;
  }
}
.mobile{

  &.button{
    width: 100%;
    padding: $boxPadding 0;
    display: block;
    text-decoration: none;
    color: $dark;
    span{
      display: inline-block;
      @include center(relative);
    }
  }
  @media (min-width: $break-xsmall){
    display: none !important;
  }
}