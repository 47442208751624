.module.multi{
  >.wrapper {
    width: 100%;
    display:block;
    @include clearfix();
    >.object {
      width: 100%;
      img {
        display:block;
        padding-bottom: $boxPadding-mobile;
        @media (min-width: $break-xsmall){
          padding-bottom: $boxPadding;
        }
      }
      &:last-of-type {
        img {
          padding-bottom: 0;
        }
      }
      @media (min-width: $break-medium) {
        width: 50%;
        float: left;
        @include box-sizing(border-box);
        &:nth-of-type(odd) {
          padding-right: $boxPadding / 2;
        }
        &:nth-of-type(even) {
          padding-left: $boxPadding / 2;
        }
        img {
          padding-bottom: 0;
        }
      }
      img {
        width: 100%;
      }
    }
  }
}