ul.module.links{
  list-style-type: none;
  padding: 0;
  @media (max-width: $break-small - 1){
    padding-top: $boxPadding;
  }
  li{
    position: relative;
    margin-bottom: 0.6em;
  }
  svg{
    position: absolute;
    left: 0;
    top: 0.74em;
    transform:translateY(-50%);
    &.deg90{
      transform:translateY(-50%) rotate(90deg);
    }
  }
  span{
    padding-left: $boxPadding * 1.3;
    display:inline-block;
  }
}